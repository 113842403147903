import React, { useContext } from "react";
import DualRangeSlider from "./DualRangeSlider";
import { Button, Container, Row } from "react-bootstrap";
import ThemeContext from "../context/ThemeContext";
import { ImSun } from "react-icons/im";
import { LuExpand } from "react-icons/lu";
import SliderContext from "../context/SliderContext";
import { AiOutlineFullscreenExit } from "react-icons/ai";

const RhythmsWithSlider = () => {

	const [ isNightMode, setIsNightMode ] = useContext(ThemeContext);
	const context = useContext(SliderContext);
	const [selectedRange, setSelectedRange] = context.range;
	const [isFullScreen, setFullScreen] = context.fullScreen;

	return (
		<div className="bg-black py-3 container-fluid">
			<Container>
				<Row>
					<div className="col-12 col-sm-8 col-md-8">
						<DualRangeSlider
							selectedRange={selectedRange}
							onChangeRange={setSelectedRange}
							isNightMode={isNightMode}
						/>
					</div>
					<div className="col-12 col-sm-4 col-md-4">
						<div className="bootstrap-container">
							<Button
								variant={isNightMode ? "dark" : "light"}
								onClick={() => setIsNightMode(!isNightMode)}
								size="sm"
								className={`custom-button ${isNightMode ? "bootstrap-night": "bootstrap-light"}`}
							> <ImSun size={ 16 } />

							</Button>
							<Button
								variant={isNightMode ? "dark" : "light"}
								onClick={() => setFullScreen(!isFullScreen)}
								size="sm"
								className={`custom-button ${isNightMode ? "bootstrap-night": "bootstrap-light"}`}>
									{isFullScreen ? <AiOutlineFullscreenExit size={ 16 } /> : <LuExpand size={ 16 } /> }
							</Button>
						</div>
					</div>
				</Row>
			</Container>
		</div>
	);
};

export default RhythmsWithSlider;
