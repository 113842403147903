import { get } from "./req";

export class DataProvider {

    static async getRhythmCodes() {
        return await get("rhythm-codes?per_page=100");
    }

    /**
     * @param {String} ids string of ids seperated by comma
     */
    static async getRhythms(ids) {
        return await get(`rhythms?include=${ids}&per_page=100&orderby=include`);
    }

    static async getRhythmByID(id) {
        return await get(`rhythms/${id}`);
    }
}