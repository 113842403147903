// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/close.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-brand {
    font-family: var(--font-acumin);
    text-transform: uppercase;
    font-weight: 800;
}

.navbar {
    background-color: #000;
}

.navbar-collapse {
    position: absolute;
    right: 0;
    top: 100%;
    text-align: left;
    background-color: #000;
    width: 400px;
    max-width: 100%;
    padding: .5rem 1.5rem;
    z-index: 999;
}

.navbar .nav-link {
    text-transform: uppercase;
    font-family: var(--font-acumin-condensed);
    font-size: x-large;
    padding: .1rem;
    word-spacing: .2rem;
}

.navbar .navbar-toggler {
    box-shadow: none;
    border: none;
    outline: none;
}

.navbar .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    filter: contrast(0);
}`, "",{"version":3,"sources":["webpack://./client/src/components/Navigation.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gBAAgB;IAChB,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,yCAAyC;IACzC,kBAAkB;IAClB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,yDAA0C;IAC1C,mBAAmB;AACvB","sourcesContent":[".navbar-brand {\n    font-family: var(--font-acumin);\n    text-transform: uppercase;\n    font-weight: 800;\n}\n\n.navbar {\n    background-color: #000;\n}\n\n.navbar-collapse {\n    position: absolute;\n    right: 0;\n    top: 100%;\n    text-align: left;\n    background-color: #000;\n    width: 400px;\n    max-width: 100%;\n    padding: .5rem 1.5rem;\n    z-index: 999;\n}\n\n.navbar .nav-link {\n    text-transform: uppercase;\n    font-family: var(--font-acumin-condensed);\n    font-size: x-large;\n    padding: .1rem;\n    word-spacing: .2rem;\n}\n\n.navbar .navbar-toggler {\n    box-shadow: none;\n    border: none;\n    outline: none;\n}\n\n.navbar .navbar-toggler:not(.collapsed) .navbar-toggler-icon {\n    background-image: url(../images/close.svg);\n    filter: contrast(0);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
