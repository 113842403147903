// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes heartbeat {
	0%, 100% {
        opacity: .7;
        transform: scale(.5);
      }
      50% {
        opacity: 1;
        transform: scale(1.2);
      }
}
.rhythm-code {
	width: 40px;
	height: 40px;
	cursor: pointer;
	transition: all .2s ease;
}

.rhythm-code:hover, .rhythm-code.active {
	transform: scale(1.5);
}
.rhythm-code:hover .rhythm-code-outer,
.rhythm-code.active .rhythm-code-outer {
	animation: none;
}

.rhythm-code-outer {
	animation-delay: .5s;
	animation-name: heartbeat;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-delay: 0s;
	animation-play-state: running;
	animation-timing-function:cubic-bezier(0.445, 0.05, 0.55, 0.95);
	transform-origin: center;
	transition: all .5s ease;
}

.rhythm-code-icon-wrapper:nth-child(2n) .rhythm-code-outer { animation-delay: -.2s }
.rhythm-code-icon-wrapper:nth-child(2n+1) .rhythm-code-outer { animation-delay: -.4s }
.rhythm-code-icon-wrapper:nth-child(3n) .rhythm-code-outer { animation-delay: -.8s; }
.rhythm-code-icon-wrapper:nth-child(5n) .rhythm-code-outer { animation-delay: -1.2s }
.rhythm-code-icon-wrapper:nth-child(7n) .rhythm-code-outer { animation-delay: -2s }`, "",{"version":3,"sources":["webpack://./client/src/components/RhythmCodeIcon.css"],"names":[],"mappings":"AAAA;CACC;QACO,WAAW;QACX,oBAAoB;MACtB;MACA;QACE,UAAU;QACV,qBAAqB;MACvB;AACN;AACA;CACC,WAAW;CACX,YAAY;CACZ,eAAe;CACf,wBAAwB;AACzB;;AAEA;CACC,qBAAqB;AACtB;AACA;;CAEC,eAAe;AAChB;;AAEA;CACC,oBAAoB;CACpB,yBAAyB;CACzB,sBAAsB;CACtB,mCAAmC;CACnC,mBAAmB;CACnB,6BAA6B;CAC7B,+DAA+D;CAC/D,wBAAwB;CACxB,wBAAwB;AACzB;;AAEA,6DAA6D,sBAAsB;AACnF,+DAA+D,sBAAsB;AACrF,6DAA6D,qBAAqB,EAAE;AACpF,6DAA6D,uBAAuB;AACpF,6DAA6D,qBAAqB","sourcesContent":["@keyframes heartbeat {\n\t0%, 100% {\n        opacity: .7;\n        transform: scale(.5);\n      }\n      50% {\n        opacity: 1;\n        transform: scale(1.2);\n      }\n}\n.rhythm-code {\n\twidth: 40px;\n\theight: 40px;\n\tcursor: pointer;\n\ttransition: all .2s ease;\n}\n\n.rhythm-code:hover, .rhythm-code.active {\n\ttransform: scale(1.5);\n}\n.rhythm-code:hover .rhythm-code-outer,\n.rhythm-code.active .rhythm-code-outer {\n\tanimation: none;\n}\n\n.rhythm-code-outer {\n\tanimation-delay: .5s;\n\tanimation-name: heartbeat;\n\tanimation-duration: 3s;\n\tanimation-iteration-count: infinite;\n\tanimation-delay: 0s;\n\tanimation-play-state: running;\n\tanimation-timing-function:cubic-bezier(0.445, 0.05, 0.55, 0.95);\n\ttransform-origin: center;\n\ttransition: all .5s ease;\n}\n\n.rhythm-code-icon-wrapper:nth-child(2n) .rhythm-code-outer { animation-delay: -.2s }\n.rhythm-code-icon-wrapper:nth-child(2n+1) .rhythm-code-outer { animation-delay: -.4s }\n.rhythm-code-icon-wrapper:nth-child(3n) .rhythm-code-outer { animation-delay: -.8s; }\n.rhythm-code-icon-wrapper:nth-child(5n) .rhythm-code-outer { animation-delay: -1.2s }\n.rhythm-code-icon-wrapper:nth-child(7n) .rhythm-code-outer { animation-delay: -2s }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
