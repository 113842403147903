import "./Home.css";
import "../../fonts/fonts.css";
import MapSection from "../../components/MapSection";
import PageTitle from "../../components/PageTitle";
function Home() {
	return (
		<>
			<PageTitle title="Unity map" />
			<MapSection />
		</>
	);
}

export default Home;
