import { useContext, useEffect, useState } from "react";

import "./MyMap.css";
import ModalVideo from "../components/ModalVideo";
import DotMap from "../components/DotMap";
import RhythmCodeIcon from "./RhythmCodeIcon";
import useGetRhythmCodes from "../hooks/useGetRhythmCodes";
import { sortByDate } from "../core/utils";
import SliderContext from "../context/SliderContext";

function MyMap({ isNightMode }) {
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedRhythm, setSelectedRhythm] = useState({});
	const [selectedRhythmCode, setSelectedRhythmCode] = useState({});
	const [selectedRange] = useContext(SliderContext).range;
	const rhythmCodes = useGetRhythmCodes();
	const sorted = rhythmCodes.sort((a, b) => sortByDate(a.date, b.date)).reverse();

	const [showItem, setShowItem] = useState(null);

	useEffect(() => {
		setShowItem(sorted[0]?.id);
	}, [sorted]);

	return (
		<div>
			<DotMap showing={showItem ? true : false}>
				{(rhythmCodes ?? []).map((item) => <RhythmCodeIcon
					key={item.id}
					rhythmCode={item}
					isNightMode={isNightMode}
					showState={[showItem, setShowItem]}
					setSelectedRhythm={setSelectedRhythm}
					setSelectedRhythmCode={setSelectedRhythmCode}
					setModalOpen={setModalOpen}
					dateRange={selectedRange}
				/>)}
			</DotMap>
			{isModalOpen && (
				<ModalVideo setModalOpen={setModalOpen} rhythm={selectedRhythm} rhythmCode={selectedRhythmCode} />
			)}
		</div>
	);
}

export default MyMap;
