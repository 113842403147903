import React, { useContext, useState } from "react";
import "./MapSection.css";
import MyMap from "../components/MyMap";
import ThemeContext from "../context/ThemeContext";
import SliderContext from "../context/SliderContext";
import RhythmsWithSlider from "./RhythmsWithSlider";

function MapSection() {
	const [isFullScreen, setFullScreen] = useState(false);
	const [selectedRange, setSelectedRange] = useState([1500, 2100]);

	const sliderState = {
		range: [selectedRange, setSelectedRange],
		fullScreen: [isFullScreen, setFullScreen]
	};

	const [isNightMode] = useContext(ThemeContext);

	const toggleFullScreen = () => {
		setFullScreen(!isFullScreen);
	};


	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			toggleFullScreen();
		}
	};

	return (
		<SliderContext.Provider value={sliderState}>
			<div
				className={`map-section-container ${isFullScreen ? "fullscreen" : ""}${
					isNightMode ? " night-container" : ""
				}`}>
				<div
					className={`map-section ${isFullScreen ? "fullscreen" : ""}`}>
					{isFullScreen ? (
						<div>
							<div
								className="exit-fullscreen-button"
								onClick={toggleFullScreen}
								onKeyDown={handleKeyDown}
								tabIndex={0}
								role="button"
								aria-pressed={isFullScreen}
							>
								Click to exit full screen mode
							</div>
							<div className="full-screen-map">
								<MyMap isNightMode={isNightMode} />
							</div>
							<RhythmsWithSlider />
						</div>
					) : (
						<div>
							<div className="map-container">
								<MyMap isNightMode={isNightMode} />
							</div>
						</div>
					)}
				</div>
				<RhythmsWithSlider />
			</div>
		</SliderContext.Provider>

	);
}

export default MapSection;
