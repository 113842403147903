import { FaPlay } from "react-icons/fa";

function RhythmItem({ setModalOpen, setSelectedRhythm, rhythm, rhythmCode, setSelectedRhythmCode }) {

	const hasMedia = rhythm.video
		|| rhythm.video_2
		|| rhythm.video_3
		|| rhythm.video_4
		|| rhythm.video_5
		|| rhythm.audio;

	const hasContent = rhythm.description
		|| rhythm.secondaryDescription
		|| hasMedia;

	const handleRhythmClick = () => {
		if (hasContent) {
			setModalOpen(true);
			setSelectedRhythm({ ...rhythm });
			setSelectedRhythmCode({ ...rhythmCode });
		}
	};

	return (
		<li {...hasContent && { onClick: handleRhythmClick }} className={`${hasMedia ? "has-media" : ""}`}>
			{hasMedia && <FaPlay color="#FF00FF" />}
			<span>{rhythm.name}</span>
		</li>
	);
}

export default RhythmItem;
