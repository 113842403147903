import React, { useState } from "react";
import { Range } from "react-range";
import "./DualRangeSlider.css";

const DualRangeSlider = ({ onChangeRange, isNightMode }) => {
	const labels = ["Precolonial", "ATL Slavery", "	Berlin Conf", "Civil Rights", "Now"];
	const exactPoints = [1300, 1600, 1884, 1965, 2020];
	const [sliderIndices, setSliderIndices] = useState([0, 4]);

	const handleChange = (newValues) => {
		setSliderIndices(newValues);
		onChangeRange([exactPoints[newValues[0]], exactPoints[newValues[1]]]);
	};

	const renderThumb = ({ props }) => (
		<div
			{...props}
			className={`range-thumb ${isNightMode ? "night-mode" : "day-mode"}`}
		/>
	);

	const renderTrack = ({ props, children }) => (
		<div {...props} className="range-track">
			{children}
			<div
				className="range-highlight"
				style={{
					width: `${((sliderIndices[1] - sliderIndices[0]) / (exactPoints.length - 1)) * 100}%`,
					left: `${(sliderIndices[0] / (exactPoints.length - 1)) * 100}%`,
					background: isNightMode ? "#B99E01" : "#000",
				}}
			></div>
		</div>
	);

	const renderMark = ({ props, index }) => (
		<div {...props} className="range-label-container">
			<div className="range-label">{labels[index]}</div>
		</div>
	);

	return (
		<div className="dual-range-container">
			<div className={`range-wrapper ${isNightMode ? "night" : "light"}`}>
				<Range
					values={sliderIndices}
					step={1}
					min={0}
					max={exactPoints.length - 1}
					onChange={handleChange}
					renderTrack={renderTrack}
					renderThumb={renderThumb}
					renderMark={renderMark}
				/>
			</div>
		</div>
	);
};

export default DualRangeSlider;
