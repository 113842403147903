import { Link } from "react-router-dom";
import "./NotFound.css";

function NotFound() {
    return (
        <div className="not-found-page">
            <div className="container py-5">
                <h6>The page you requested cannot be found</h6>
                <Link to={"/"}>Back to home</Link>
            </div>
        </div>
    );
}

export default NotFound;