// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
	margin: 0;
	border: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: var(--font-acumin);
}

html {
	font-size: 16px;
}

.home {
	display: flex;
	max-width: 1440px;
	font-family: var(--font-acumin);
}

h1, h2, h3, h4, h5, h6 {
	font-family: var(--font-superior);
}`, "",{"version":3,"sources":["webpack://./client/src/pages/Home/Home.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,SAAS;CACT,UAAU;CACV,sBAAsB;CACtB,+BAA+B;AAChC;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,iBAAiB;CACjB,+BAA+B;AAChC;;AAEA;CACC,iCAAiC;AAClC","sourcesContent":["* {\n\tmargin: 0;\n\tborder: 0;\n\tpadding: 0;\n\tbox-sizing: border-box;\n\tfont-family: var(--font-acumin);\n}\n\nhtml {\n\tfont-size: 16px;\n}\n\n.home {\n\tdisplay: flex;\n\tmax-width: 1440px;\n\tfont-family: var(--font-acumin);\n}\n\nh1, h2, h3, h4, h5, h6 {\n\tfont-family: var(--font-superior);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
