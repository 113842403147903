import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "./ModalVideo.css";
import { FaAlignLeft, FaPlay } from "react-icons/fa";
import ReactPlayer from "react-player";
import { IoMdClose } from "react-icons/io";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";
import DOMPurify from "dompurify";

function ModalVideo({ rhythm, rhythmCode, setModalOpen }) {
	const [activeTab, setActiveTab] = useState(rhythm.video ? "video" : "description");
	const playerItems = [rhythm.video, rhythm.video_2, rhythm.video_3, rhythm.video_4, rhythm.video_5, rhythm.audio].filter((item) => item);
	const [activeMedia, setActiveMedia] = useState(playerItems.length > 0 ? 0 : undefined);
	const safeDescription = DOMPurify.sanitize(rhythm.description);
	const safeSecDescription = DOMPurify.sanitize(rhythm.secondary_description);

	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	function PlayerPrev() {
		function handlePlayerPrev() {
			setActiveMedia(activeMedia - 1);
		}

		return activeMedia > 0 ? <a onClick={handlePlayerPrev}><HiChevronDoubleLeft /></a> : null;
	}

	function PlayerNext() {
		function handlePlayerNext() {
			setActiveMedia(activeMedia + 1);
		}

		return activeMedia < playerItems.length - 1 ? <a onClick={handlePlayerNext}><HiChevronDoubleRight /></a> : null;
	}

	function TabNav() {
		return (
			<Nav variant="tabs">
			{rhythm.video &&
				<Nav.Item>
					<Nav.Link
						eventKey="video"
						onClick={() => handleTabChange("video")}>
						<FaPlay color={activeTab === "video" ? "#000" : "#fff"} />
					</Nav.Link>
				</Nav.Item>}
				{rhythm.description &&
				<Nav.Item>
					<Nav.Link
						eventKey="description"
						onClick={() => handleTabChange("description")}>
						<FaAlignLeft color={activeTab === "description" ? "#000" : "#fff"} />
					</Nav.Link>
				</Nav.Item>}
				<IoMdClose size={30} color="#fff" onClick={() => setModalOpen(false)} cursor={"pointer"} className="modal-close" />
			</Nav>
		);
	}

	function TabContent() {
		return (
			<Tab.Content>
				{playerItems[activeMedia] !== undefined &&
				<Tab.Pane eventKey="video">
					<div className='aspect-ratio-parent aspect-ratio-16by9'>
						<ReactPlayer
							url={playerItems[activeMedia]}
							className="aspect-ratio-content overflow-hidden"
							width="100%"
							height="100%"
							controls
							playing
							muted />
					</div>
				</Tab.Pane> }
				{rhythm.description &&
				<Tab.Pane eventKey="description">
					<div className="aspect-ratio-parent aspect-ratio-16by9">
						<div className="aspect-ratio-content">
							<div className="container p-3 p-lg-4">
								<div className="row">
									<div className={`col-12 ${rhythm.secondary_description ? "col-lg-7" : ""}`}>
										<div
											className="modal-text"
											dangerouslySetInnerHTML={{ __html: safeDescription }} />
									</div>
									{rhythm.secondary_description && <div className="col-12 col-lg-5">
										<div
											className="modal-text secondary-description"
											dangerouslySetInnerHTML={{ __html: safeSecDescription }} />
									</div>}
								</div>
							</div>
						</div>
					</div>
				</Tab.Pane> }
			</Tab.Content>
		);
	}

	return (
		<Modal className="rhythm-modal" size="lg" show={true} onHide={() => setModalOpen(false)}>
			<Modal.Body>
				<Tab.Container activeKey={activeTab}>
					<TabNav />
					<TabContent />
					<div className="modal-footer">
						{activeTab === "video" && playerItems.length > 1
						&& <div className="modal-footer--pagination">
							<div>
								<PlayerPrev />
								{playerItems.map((_, index) => {
									return <a
										key={index}
										className={activeMedia === index ? "active" : ""}
										onClick={() => setActiveMedia(index)}>{index + 1}</a>;
								})}
								<PlayerNext />
							</div>
						</div>}
						<div className="modal-footer--title">
							<span>{rhythmCode.name} : </span><span>{rhythm.name}</span>
						</div>
					</div>
				</Tab.Container>
			</Modal.Body>
		</Modal>
	);
}

export default ModalVideo;
