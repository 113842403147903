import React from "react";
import "./PageTitle.css";

const PageTitle = ({ title }) => {

    return <div className="page-title">
        <div className="container">
            <h2 className="title">{title}</h2>
        </div>
    </div>;
};

export default PageTitle;