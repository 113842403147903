import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";

import Main from "./layouts/Main";
import NotFound from "./pages/NotFound/NotFound";
import { useState } from "react";
import ThemeContext from "./context/ThemeContext";

const App = () => {
	const [ isNightMode, setIsNightMode ] = useState(true);

	return (
		<ThemeContext.Provider value={[isNightMode, setIsNightMode]}>
			<BrowserRouter>
				<Routes>
					<Route element={ <Main /> }>
						<Route path="/" element={<Home />} />
						<Route path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</ThemeContext.Provider>
	);
};



export default App;
