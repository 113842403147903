// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title {
    display: flex;
    padding: 1rem 0;
    text-transform: uppercase;
    background-color: rgb(0, 0, 0);
}

.page-title .title {
    margin: 0;
    font-family: var(--font-acumin-condensed);
    color: rgb(255, 255, 255);
}

@media screen and (max-width: 991.98px) {
    .page-title .title {
        font-size: 1.6rem;
    }
}`, "",{"version":3,"sources":["webpack://./client/src/components/PageTitle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,yBAAyB;IACzB,8BAA8B;AAClC;;AAEA;IACI,SAAS;IACT,yCAAyC;IACzC,yBAAyB;AAC7B;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".page-title {\n    display: flex;\n    padding: 1rem 0;\n    text-transform: uppercase;\n    background-color: rgb(0, 0, 0);\n}\n\n.page-title .title {\n    margin: 0;\n    font-family: var(--font-acumin-condensed);\n    color: rgb(255, 255, 255);\n}\n\n@media screen and (max-width: 991.98px) {\n    .page-title .title {\n        font-size: 1.6rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
