import { useEffect, useState } from "react";
import { DataProvider } from "../core/data";
import { normalize } from "../core/utils";

/**
 * Hook to fetch Rhythms.
 * @remarks Category with slug `rhythms` must exist in Wordpress and Rhythms must be under that category
 */
export default function useGetRhythms(idArr) {
    const [data, setData] = useState();
    const ids = Array.isArray(idArr) && idArr.length > 0 ? idArr.join(",") : null;

    useEffect(() => {
        async function load() {
            if(ids) {
                const rhythms = await DataProvider.getRhythms(ids);
                setData(normalize(rhythms));
            }
        }

        load();

    }, []);

    return data;
}