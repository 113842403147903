import React from "react";
import "./Hero.css";

const Hero = () => {
	return (
		<div className="hero">
			<div className="hero-container">
				<div className="hero-text">
					<h1>Black Atlantic Rhythm Map</h1>
					<h4>A Journey through ancestral codes</h4>
				</div>
			</div>
		</div>
	);
};

export default Hero;
