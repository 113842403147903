// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.night-container .map-section:not(.fullscreen) #dotmap {
	background-image: linear-gradient(
		to right,
		#0c1330,
		#0c1330,
		#182049,
		#1e2c5f,
		#182049,
		#0c1330,
		#0c1330
	);
}

#dotmap .map-circle {
	fill: black;
}

#dotmap {
	margin: 1em;
	padding: 0 1em;
	overflow: visible;
}

#dotmap foreignObject {
	overflow: visible;
}

#dotmap .map-title {
	transition: all .5s ease;
}`, "",{"version":3,"sources":["webpack://./client/src/components/DotMap.css"],"names":[],"mappings":"AAAA;CACC;;;;;;;;;EASC;AACF;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,WAAW;CACX,cAAc;CACd,iBAAiB;AAClB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,wBAAwB;AACzB","sourcesContent":[".night-container .map-section:not(.fullscreen) #dotmap {\n\tbackground-image: linear-gradient(\n\t\tto right,\n\t\t#0c1330,\n\t\t#0c1330,\n\t\t#182049,\n\t\t#1e2c5f,\n\t\t#182049,\n\t\t#0c1330,\n\t\t#0c1330\n\t);\n}\n\n#dotmap .map-circle {\n\tfill: black;\n}\n\n#dotmap {\n\tmargin: 1em;\n\tpadding: 0 1em;\n\toverflow: visible;\n}\n\n#dotmap foreignObject {\n\toverflow: visible;\n}\n\n#dotmap .map-title {\n\ttransition: all .5s ease;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
