// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/header-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
	background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
}

.hero-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: rgba(25,25,25,.2);
}

.hero-text {
	color: white;
	text-align: center;
}

.hero-text h1 {
	font-size: 3.5rem;
	margin-bottom: 0;
	font-variant: small-caps;
}

.hero-text h4 {
	font-size: 1.4rem;
	text-transform: uppercase;
}

@media screen and (max-width: 1399.98px) {
	.hero-text h1 { font-size: 3rem }
	.hero-text h4 { font-size: 1.2rem;}
}

@media screen and (max-width: 1199.98px) {
	.hero-text h1 { font-size: 2.5rem }
	.hero-text h4 { font-size: 1rem;}
}

@media screen and (max-width: 767.98px) {
	.hero-text h1 { font-size: 2rem }
	.hero-text h4 { font-size: .875rem;}
}

@media screen and (max-width: 575.98px) {
	.hero-text h1 { font-size: 1.8rem }
	.hero-text h4 { font-size: .75rem;}
}`, "",{"version":3,"sources":["webpack://./client/src/components/Hero.css"],"names":[],"mappings":"AAAA;CACC,mDAA0C;CAC1C,wBAAwB;CACxB,4BAA4B;CAC5B,sBAAsB;AACvB;;AAEA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,iBAAiB;CACjB,oBAAoB;CACpB,mCAAmC;AACpC;;AAEA;CACC,YAAY;CACZ,kBAAkB;AACnB;;AAEA;CACC,iBAAiB;CACjB,gBAAgB;CAChB,wBAAwB;AACzB;;AAEA;CACC,iBAAiB;CACjB,yBAAyB;AAC1B;;AAEA;CACC,gBAAgB,gBAAgB;CAChC,gBAAgB,iBAAiB,CAAC;AACnC;;AAEA;CACC,gBAAgB,kBAAkB;CAClC,gBAAgB,eAAe,CAAC;AACjC;;AAEA;CACC,gBAAgB,gBAAgB;CAChC,gBAAgB,kBAAkB,CAAC;AACpC;;AAEA;CACC,gBAAgB,kBAAkB;CAClC,gBAAgB,iBAAiB,CAAC;AACnC","sourcesContent":[".hero {\n\tbackground: url(\"../images/header-bg.jpg\");\n\tbackground-position: top;\n\tbackground-repeat: no-repeat;\n\tbackground-size: cover;\n}\n\n.hero-container {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tpadding-top: 3rem;\n\tpadding-bottom: 3rem;\n\tbackground-color: rgba(25,25,25,.2);\n}\n\n.hero-text {\n\tcolor: white;\n\ttext-align: center;\n}\n\n.hero-text h1 {\n\tfont-size: 3.5rem;\n\tmargin-bottom: 0;\n\tfont-variant: small-caps;\n}\n\n.hero-text h4 {\n\tfont-size: 1.4rem;\n\ttext-transform: uppercase;\n}\n\n@media screen and (max-width: 1399.98px) {\n\t.hero-text h1 { font-size: 3rem }\n\t.hero-text h4 { font-size: 1.2rem;}\n}\n\n@media screen and (max-width: 1199.98px) {\n\t.hero-text h1 { font-size: 2.5rem }\n\t.hero-text h4 { font-size: 1rem;}\n}\n\n@media screen and (max-width: 767.98px) {\n\t.hero-text h1 { font-size: 2rem }\n\t.hero-text h4 { font-size: .875rem;}\n}\n\n@media screen and (max-width: 575.98px) {\n\t.hero-text h1 { font-size: 1.8rem }\n\t.hero-text h4 { font-size: .75rem;}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
