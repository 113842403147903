import "./Main.css";
import "../fonts/fonts.css";
import Hero from "../components/Hero";
import Navigation from "../components/Navigation";
import { Outlet } from "react-router-dom";

function Main() {
	return (
		<main role="main">
			<Navigation />
			<Hero />
				<Outlet />
		</main>
	);
}

export default Main;
