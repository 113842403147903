import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

import App from "./App";

axios.defaults.baseURL = "https://api.unityatlantic.org/wp-json/wp/v2";
axios.defaults.headers.get["Content-Type"] = "application/json";

createRoot(document.getElementById("root")).render(<App />);
