import axios from "axios";


export async function get(endpoint, params) {
    try {
        const response = await axios.get(endpoint, params);
        return response.data;
    } catch (e) {
        console.error("Encountered error while performing GET", e);
    }
}