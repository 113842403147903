import { useEffect, useState } from "react";
import { DataProvider } from "../core/data";
import { normalize } from "../core/utils";

/**
 * Hook to fetch Rhythm Codes.
 * @remarks Category with slug `rhythm-codes` must exist in Wordpress and Rhythms must be under that category
 */
export default function useGetRhythmCodes() {
    const [data, setData] = useState([]);

    useEffect(() => {

        async function load() {
            const rhythmCodes = await DataProvider.getRhythmCodes();
            setData(normalize(rhythmCodes));
        }

        load();

    }, []);

    return data;
}