import "./RhythmCodeIcon.css";
import "./RhythmItem.css";
import RhythmItem from "./RhythmItem";
import useGetRhythms from "../hooks/useGetRhythms";

function RhythmCodeIcon({ rhythmCode, isNightMode, setSelectedRhythm, setSelectedRhythmCode, setModalOpen, dateRange, showState }) {
	const rhythms = useGetRhythms(rhythmCode.rhythms);
	const filteredRhythms = rhythms?.filter(filterByDate) ?? [];
	const dotSelector = document.querySelector(`#${rhythmCode.map_id}`);
	const [showItem, setShowItem] = showState;
	const enabled = showItem === rhythmCode.id;

	if (!dotSelector) {
		return;
	}

	const dotHtml = dotSelector.outerHTML;
	const findxy = /([+-]?\d*\.?\d+)\s+([+-]?\d*\.?\d+)/;
	const xyData = dotHtml.match(findxy);
	const xOffset = 10;
	const yOffset = 4.5;

	// temporary offset fix
	const xValue = xyData[1] - xOffset;
	const yValue = xyData[2] - yOffset;

	function filterByDate(rhythm) {
		const start = rhythm.year_start;
		const end = rhythm.year_end;

		return (
			(start >= dateRange[0] || !start) &&
			(end <= dateRange[1] || !end)
		);
	}

	function handleClick() {
		setShowItem(rhythmCode.id === showItem ? null : rhythmCode.id);
	}

	if (!filteredRhythms.length > 0) {
		return;
	}

	return (
		<div
			className={`rhythm-code-icon-wrapper ${rhythmCode?.position}`}
			style={{
				position: "absolute",
				top: `${yValue}px`,
				left: `${xValue}px`,
			}}
		>
			<svg
				className={`rhythm-code ${enabled ? "active" : ""}`}
				onClick={handleClick}
			>
				<circle className="rhythm-code-outer" cx="20" cy="20" r="10" fill={isNightMode ? "#ffffff77" : "#ffbeff"} />
				<circle
					cx="20"
					cy="20"
					r="6"
					fill={isNightMode ? "#fff" : "#ff00ff"}
				/>
			</svg>
			<div
				className="rhythm-item-list"
			>
				<div className="rhythm-code-name">{rhythmCode.name}</div>
				{enabled && <ul>
						{filteredRhythms.map((rhythm) => {
						return (
							<RhythmItem
								key={rhythm.id}
								setModalOpen={setModalOpen}
								setSelectedRhythm={setSelectedRhythm}
								setSelectedRhythmCode={setSelectedRhythmCode}
								rhythm={rhythm}
								rhythmCode={rhythmCode}
							/>
						);
					})}
					</ul>}
			</div>
		</div>
	);
}

export default RhythmCodeIcon;
