
/**
 * Normalizes wordpress post to specified structure
 * @param raw The raw post data, fetched straight from Wordpress API
 */
export function normalize(raw) {
    function normalizeItem(item) {
        return {
            ...item.acf,
			id: item.id,
			name: item.title.rendered,
			date: item.date,
			status: item.status,
			description: item?.content?.rendered,
        };
    }

    if (!Array.isArray(raw)) {
        return normalizeItem(raw);
    }

    return raw.map((item) => normalizeItem(item));
}

/**
 * Sorts an array of dates. Default newest to oldest
 * @param {String} a Must be a valid date string
 * @param {*} b Must be a valid date string
 */
export function sortByDate(a, b) {
    return new Date(a) - new Date(b);
}