// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-page {
    background-image: linear-gradient(
		to right,
		#0c1330,
		#0c1330,
		#182049,
		#1e2c5f,
		#182049,
		#0c1330,
		#0c1330
	);
}

.not-found-page, .not-found-page a {
	color: #fff;
}`, "",{"version":3,"sources":["webpack://./client/src/pages/NotFound/NotFound.css"],"names":[],"mappings":"AAAA;IACI;;;;;;;;;EASF;AACF;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".not-found-page {\n    background-image: linear-gradient(\n\t\tto right,\n\t\t#0c1330,\n\t\t#0c1330,\n\t\t#182049,\n\t\t#1e2c5f,\n\t\t#182049,\n\t\t#0c1330,\n\t\t#0c1330\n\t);\n}\n\n.not-found-page, .not-found-page a {\n\tcolor: #fff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
