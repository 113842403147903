import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Navigation.css";
import { NavLink } from "react-router-dom";

function Navigation() {

  function getLinkStatus( { isActive, isPending } ) {
    return `${isPending ? "pending" : isActive ? "active" : ""} nav-link`;
  }

  function handleClick() {
    document.querySelector(".navbar-toggler[type='button']").click();
  }

  return (
    <Navbar expand={false} variant="dark">
      <Container fluid="xxl" className="position-relative">
        <Navbar.Brand>Unity</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink onClick={handleClick} to="/" className={getLinkStatus}>Unity Map</NavLink>
            <Nav.Link onClick={handleClick} href="https://unityatlantic.org/map/share-a-code" className={getLinkStatus}>Share a rhythm</Nav.Link>
            <Nav.Link onClick={handleClick} href="https://unityatlantic.org/map/credits" className={getLinkStatus}>Map Credits</Nav.Link>
            <Nav.Link onClick={handleClick} href="https://unityatlantic.org/map/black-atlantic-space-time/" className={getLinkStatus}>Black Atlantic Series</Nav.Link>
            <hr />
            <Nav.Link onClick={handleClick} href="https://unityatlantic.org/map/about" className={getLinkStatus}>About us</Nav.Link>
            <Nav.Link onClick={handleClick} href="https://unityatlantic.org/map/research/" className={getLinkStatus}>Research</Nav.Link>
            <Nav.Link onClick={handleClick} href="https://unityatlantic.org/map/contact" className={getLinkStatus}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;