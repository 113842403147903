// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map {
	position: relative;
	top: 0;
	left: 0;
}
`, "",{"version":3,"sources":["webpack://./client/src/components/MyMap.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,MAAM;CACN,OAAO;AACR","sourcesContent":[".map {\n\tposition: relative;\n\ttop: 0;\n\tleft: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
